<template>
  <el-select
    v-model="localValue"
    :remote-method="search"
    :placeholder="$t('pages.emails.compose.select_relations')"
    :loading="isLoadingContacts"
    class="relationSearch EmailRecipientSelector"
    data-testid="ComposeEmail__sendTo"
    popper-class="relationSearchModal"
    multiple
    filterable
    remote
    reserve-keyword
    @blur="searchQuery = ''"
  >
    <div
      v-if="filteredGroups.length"
      class="has-text-weight-bold p-l-s p-b-s"
      data-testid="RecipientOption__RelationGroupTitle"
    >
      {{ $t('general.relation_groups') }}
    </div>
    <el-option
      v-for="group in filteredGroups"
      :key="group.id"
      :label="`${group.name} (${group.relations_count} ${$t('pages.emails.compose.recipients')})`"
      :value="group.id"
      data-testid="RecipientOption__RelationGroup"
    />
    <v-separator
      v-if="relations.length && filteredGroups.length"
      darker
      class="m-v-m"
      data-testid="RecipientOptions__Separator"
    />

    <div
      v-if="relations.length || attachedRecipient"
      class="has-text-weight-bold p-l-s p-b-s"
      data-testid="RecipientOption__RelationTitle"
    >
      {{ $t('general.relations') }}
    </div>
    <el-option
      v-for="relation in relations"
      :key="relation.id"
      :label="relation.preferred_name"
      :value="relation.id"
      data-testid="RecipientOption__Relation"
    />
    <!-- This is the recipient we want to force send to -->
    <el-option
      v-if="attachedRecipient"
      key="attachedRecipient"
      :label="attachedRecipient.name"
      :value="attachedRecipient.relation_id"
      data-testid="RecipientOption__AttachedRecipient"
    />
  </el-select>
</template>

<script>
import { RelationsApiService } from '@/services/api/RelationsApiService'

import { debounceMixin } from '@hypefactors/shared/js/mixins/debounceMixin'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * @module EmailRecipientSelector
 */
export default {
  name: 'EmailRecipientSelector',

  mixins: [debounceMixin(['search'])],

  props: {
    value: {
      type: Array,
      required: true
    },
    attachedRecipient: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      relations: [],
      relationGroups: [],

      searchQuery: '',

      isLoadingRelations: false,
      isLoadingRelationGroups: false
    }
  },

  computed: {
    localValue: generateComputedSetterWithEmit(),
    filteredGroups () {
      if (!this.searchQuery) return this.relationGroups

      return this.relationGroups.filter(group => {
        const groupName = (group.name || '').toLowerCase()

        return groupName.includes(this.searchQuery.toLowerCase())
      })
    },
    isLoadingContacts () {
      return this.isLoadingRelations || this.isLoadingRelationGroups
    }
  },

  watch: {
    activeBrandId: {
      immediate: true,
      handler: 'fetchData'
    }
  },

  methods: {
    fetchData (brandId) {
      this.search()
    },

    fetchRelations (query) {
      this.isLoadingRelations = true

      return RelationsApiService.findRelations(query, {
        brands: [this.activeBrandId]
      })
        .then((relations) => {
          this.relations = relations
          this.isLoadingRelations = false
        })
        .catch((error) => {
          if (error === 'cancel') return
          this.$displayRequestError(error)
          this.isLoadingRelations = false
        })
    },

    fetchRelationGroups (query) {
      this.isLoadingRelationGroups = true

      return RelationsApiService.fetchGroups({
        brand: this.activeBrandId,
        sort: 'a_z',
        search: query
      })
        .then(response => {
          this.relationGroups = response
          this.$emit('groups', response)
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          this.isLoadingRelationGroups = false
        })
    },

    search (query = '') {
      this.searchQuery = query

      this.fetchRelations(query)

      this.fetchRelationGroups(query)
    }
  }
}
</script>
