import _orderBy from 'lodash/orderBy'

import Api from '@/services/Api'

const GET_RELATIONS_ENDPOINT = '/connect/relations'
const GET_RELATION_GROUPS_ENDPOINT = '/connect/groups'

let findRelationsToken = null

export const RelationsApiService = {
  findRelations (query, params) {
    findRelationsToken && findRelationsToken.cancel()
    findRelationsToken = Api.cancelToken()

    params = {
      params: {
        search: query,
        ...params
      },
      cancelToken: findRelationsToken.token
    }
    return Api.get(GET_RELATIONS_ENDPOINT, params)
      .then((response) => {
        return _orderBy(response.data.data, 'name')
      })
      .catch((error) => {
        // eslint-disable-next-line no-throw-literal
        if (Api.isCancelToken(error)) throw 'cancel'
        throw error
      })
  },

  fetchGroups (params) {
    return Api.get(GET_RELATION_GROUPS_ENDPOINT, { params })
      .then(r => r.data.data)
  }
}
