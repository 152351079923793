<template>
  <div v-loading="isLoading" class="ClippingAttachment">
    <clipping-list-item v-if="entity" :clipping="entity" read-only />
  </div>
</template>

<script>
import EmailAttachmentMixin from '@/components/emails/compose/EmailAttachmentMixin'
import ClippingListItem from '@/components/clippings/ClippingListItem'

export default {
  components: { ClippingListItem },
  mixins: [EmailAttachmentMixin],
  computed: {
    url () {
      return `clippings/${this.entityId}`
    },
    title () {
      return this.entity.headline
    },
    error () {
      return this.$t('errors.cannot_fetch_email_clipping_attachment')
    }
  }
}
</script>
