/**
 * Returns a list of possible Font Sizes
 * @return {number[]}
 */
export const HF_CUSTOM_FONT_SIZES = () => [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32]

export const HF_CUSTOM_STYLE_FONT_FAMILIES = () => ([
  { value: 'open-sans', label: 'Open Sans', rawValue: '\'Open Sans\', sans-serif' },
  { value: 'tahoma', label: 'Tahoma', rawValue: 'Tahoma, Geneva, sans-serif' },
  { value: 'arial', label: 'Arial', rawValue: 'Arial, \'Helvetica Neue\', Helvetica, sans-serif' },
  { value: 'arial-black', label: 'Arial Black', rawValue: '\'Arial Black\', Gadget, sans-serif' },
  { value: 'courier', label: 'Courier New', rawValue: '\'Courier New\', monospace' },
  { value: 'lucida', label: 'Lucida Console', rawValue: '\'Lucida Console\', Monaco5, monospace' },
  { value: 'lucida-sans', label: 'Lucida Sans', rawValue: '\'Lucida Sans Unicode\', \'Lucida Grande\', sans-serif' },
  { value: 'times', label: 'Times New Roman', rawValue: '\'Times New Roman\', Times, serif' },
  { value: 'verdana', label: 'Verdana', rawValue: 'Verdana, Geneva, sans-serif;' },
  { value: 'georgia', label: 'Georgia', rawValue: '\'Georgia\', serif' }
])
