<template>
  <div class="SocialShareAttachment">
    <div class="SocialShareAttachment__Visible is-flex">
      <div class="m-r-m">
        Share on:
      </div>
      <div class="buttons m-b-m">
        <v-button
          class="is-facebook is-small"
          @click.prevent="openSettingsDialog('facebook')"
        >
          Facebook
        </v-button>
        <v-button
          class="is-twitter is-small"
          @click.prevent="openSettingsDialog('twitter')"
        >
          Twitter
        </v-button>
        <v-button
          class="is-linkedin is-small"
          @click.prevent="openSettingsDialog('linkedin')"
        >
          LinkedIn
        </v-button>
      </div>
    </div>
    <el-dialog
      :visible.sync="isSettingsDialogVisible"
      :title="$t('components.social_share_attachment.title')"
      class="SocialShareAttachment-Dialog"
      @closed="reset"
    >
      <div class="m-b-m">
        <form-field :validator="$v.form.source" :label="$t('components.social_share_attachment.source')">
          <input
            v-model="$v.form.source.$model"
            class="input"
            type="text"
            name="source"
            :placeholder="$t('components.social_share_attachment.source')"
            data-testid="attachmentSource"
          >
        </form-field>
        <form-field
          v-if="form.socialNetwork === 'twitter'"
          :validator="$v.form.content"
          :label="$t('components.social_share_attachment.content')"
        >
          <autosize-textarea
            v-model="$v.form.content.$model"
            class="input"
            type="text"
            name="content"
            :placeholder="$t('components.social_share_attachment.content')"
            data-testid="attachmentContent"
          />
          <div class="has-text-right" :class="[ form.content.length < 250 ? 'has-text-success': 'has-text-danger' ]">
            {{ $t('components.social_share_attachment.chars_left', { count: 250 - form.content.length }) }}
          </div>
        </form-field>
      </div>
      <div class="is-flex m-b-m">
        <heading size="4" :serif="false">
          {{ $t('general.preview') }}:
        </heading>
        <div ref="content" class="m-l-m SocialShare__Preview">
          <a
            :href="generatedUrl"
            :class="'has-background-'+form.socialNetwork"
            class="attachment-button fr---skip-styling"
            target="_blank"
            data-testid="attachmentPreview"
          >
            {{ $t(`components.media_types.${form.socialNetwork}`) }}
          </a>
        </div>
      </div>
      <div class="buttons is-right">
        <v-button class="is-plain is-black" @click="isSettingsDialogVisible = false">
          {{ $t('forms.cancel') }}
        </v-button>
        <v-button class="is-primary" data-testid="attachTrigger" @click="attach">
          {{ $t('components.social_share_attachment.attach') }}
        </v-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import Form from '@/services/forms/Form'
import AutosizeTextarea from '@hypefactors/shared/js/components/core/AutosizeTextarea'
import { maxLength, url, requiredIf } from 'vuelidate/lib/validators'

/**
 * @module SocialShareAttachment
 */
export default {
  name: 'SocialShareAttachment',
  components: { AutosizeTextarea },
  data () {
    return {
      emitOnReady: false,
      form: new Form({
        socialNetwork: { value: '' },
        // required if not twitter
        source: { value: '', rules: { required: requiredIf((form) => form.socialNetwork !== 'twitter'), url } },
        // Required if twitter and have no source
        content: { value: '', rules: { maxLength: maxLength(250), required: requiredIf(f => f.socialNetwork === 'twitter' && !f.source) } }
      }),
      isSettingsDialogVisible: false
    }
  },
  computed: {
    generatedUrl () {
      let { source, content } = this.form
      content = encodeURIComponent(content)
      source = encodeURIComponent(source)
      switch (this.form.socialNetwork) {
        case 'facebook':
          return `https://www.facebook.com/sharer/sharer.php?u=${source}&description=${content}`
        case 'twitter':
          return `https://twitter.com/intent/tweet?text=${content}&url=${source}`
        case 'linkedin':
          return `https://www.linkedin.com/shareArticle?mini=true&url=${source}&summary=${content}`
        default:
          return ''
      }
    }
  },
  mounted () {
    this.$emit('update:loading', false)
  },
  validations () {
    return {
      form: this.form.rules()
    }
  },
  methods: {
    reset () {
      this.form.reset()
      this.$v.form.$reset()
    },
    openSettingsDialog (socialNetwork) {
      this.form.socialNetwork = socialNetwork
      this.isSettingsDialogVisible = true
    },
    attach () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.$emit('attach', {
        subject: '',
        body: this.$refs.content.innerHTML,
        skipSignature: true
      })
      this.isSettingsDialogVisible = false
    }
  }
}
</script>
<style lang="scss">

.attachment-button {
  padding: 4px 25px;
  border-radius: 3px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;

  & + & {
    margin-left: 10px;
  }

  &:hover {
    color: white;
  }
}
</style>
