<template>
  <div class="InlineStory">
    <template v-if="story.prDate">
      {{ story.prDate }}
    </template>
    <h1 :class="getCustomStyleClassesFor('headline')">
      {{ story.headline }}
    </h1>
    <h2 v-if="story.subheadline" :class="getCustomStyleClassesFor('subheadline')">
      {{ story.subheadline }}
    </h2>
    <div :class="getCustomStyleClassesFor('copytext')" class="StoryFull__content" v-html="story.copytext" />
    <br>
    <template v-if="story.boilerplate">
      <div :class="getCustomStyleClassesFor('boilerplate')" v-html="story.boilerplate" />
      <br>
    </template>
    <hr>
    <br>
    <template v-if="story.contact">
      <div :class="getCustomStyleClassesFor('contact')" v-html="story.contact" />
      <br>
      <hr>
    </template>
  </div>
</template>

<script>
import _merge from 'lodash/merge'

/**
 * @module InlineStory
 */
export default {
  name: 'InlineStory',
  props: {
    /** @type HF_Story */
    story: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    mergedStyle () {
      return _merge(this.story.prStyles.styles, this.story.prStyles.overrides)
    }
  },
  methods: {
    getCustomStyleClassesFor (field) {
      return {
        [`has-text-${this.mergedStyle[field].fontSize}`]: this.mergedStyle[field].fontSize,
        [`has-font-${this.mergedStyle[field].fontFamily}`]: this.mergedStyle[field].fontFamily
      }
    }
  }
}
</script>
