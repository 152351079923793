import _get from 'lodash/get'

import { croppieDataFactory } from '@hypefactors/shared/js/factories/croppie'
import { EMPTY_STYLES_FACTORY, PR_STYLES_FACTORY } from '../factories/pr_story'
import { Model } from './Model'
import { Newsroom } from './Newsroom'
import { Brand } from './Brand'

/**
 * Structure of the PR Style template include coming from the API
 * @typedef {Object} HF_StoryPRStyles
 * @property {string} pr_style_id - The applied style template id
 * @property {HF_StoryPRStyles} styles - The styles from the applied style template
 * @property {HF_StoryPRStyles|{}} overrides - The override styles
 */

/**
 * Creates an empty Story
 * @alias HF_Story
 */
export class Story extends Model {
  constructor (story = {}) {
    super()
    /** @type {String|null} */
    this.id = story.id || null

    /** @type {String} */
    this.slug = story.slug || ''

    /** @type {String} */
    this.newsroomSlug = story.newsroom_slug || ''

    /** @type {string} */
    this.type = story.type || ''

    /** @type {croppieData} */
    this.cover = story.cover || croppieDataFactory()

    /** @type {string} */
    this.cover_caption = story.cover_caption || ''

    /** @type {Boolean} */
    this.isPublished = story.is_published || false

    /** @type {string} */
    this.publishedAt = story.published_at || ''

    /** @type {string} */
    this.updatedAt = story.updated_at || ''

    /** @type {string} */
    this.firstPublishedAt = story.first_published_at || ''

    /** @type {String|null} */
    this.publishAt = story.publish_at || null

    /** @type {Boolean} */
    this.isPinned = story.is_pinned || false

    /** @type {String} */
    this.headline = story.headline || ''

    /** @type {String} */
    this.subheadline = story.subheadline || ''

    /** @type {String} */
    this.copytext = story.copytext || ''

    /** @type {String} */
    this.excerpt = story.excerpt || ''

    /** @type {String} */
    this.boilerplate = story.boilerplate || ''

    /** @type {String} */
    this.contact = story.contact || ''

    /** @type {Number} */
    this.filesCount = story.files_count || 0

    /** @type {Boolean} */
    this.inPRMode = story.in_pr_mode || false

    /** @type {String} */
    this.prDate = story.pr_date || ''

    /** @type {?{pdf_url: string, image_url: string}} */
    this.previews = story.previews || null

    /** @type {HF_StoryPRStyles} */
    this.prStyles = _get(story, 'pr_styles.data', {
      pr_style_id: null,
      styles: PR_STYLES_FACTORY(),
      overrides: EMPTY_STYLES_FACTORY()
    })

    /** @type {?Object} language */
    this.language = _get(story, 'language.data', null)

    /** @type {Array} */
    this.files = _get(story, 'files.data', [])

    /** @type {Array} */
    this.contacts = _get(story, 'contacts.data', [])

    /** @type {HF_Category[]} categories */
    this.categories = _get(story, 'categories.data', [])

    /** @type {Array} */
    this.tags = _get(story, 'tags.data', [])

    /** @type {!Newsroom} */
    this.newsroom = Model.getAndInstantiate(story, 'newsroom.data', Newsroom)

    /** @type {!HF_Brand} */
    this.brand = Model.getAndInstantiate(story, 'brand.data', Brand)
  }
}
