import _get from 'lodash/get'
import { Newsroom } from './Newsroom'

/**
 * @alias HF_Brand
 */
export class Brand {
  constructor (brand) {
    this.id = brand.id
    this.slug = brand.slug
    this.name = brand.name
    this.currencyCode = brand.currency_code
    this.logo = brand.logo
    this.isInDemoMode = brand.is_in_demo_mode

    this.streamsCount = brand.streams_count

    this.autoFollowNewlyCreatedStreams = brand.auto_follow_newly_created_streams
    this.userFollowedStreamsCount = brand.user_followed_streams_count

    // Timestamps
    this.createdAt = brand.created_at
    this.updatedAt = brand.updated_at

    this.newsrooms = _get(brand, 'newsrooms.data', []).map((newsroom) => new Newsroom(newsroom))
    this.country = _get(brand, 'country.data', null)
  }
}
