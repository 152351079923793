<template>
  <div class="StoryAttachment is-hidden">
    {{ $t('components.compose_email.story_attachment.hi') }}, <br>
    {{ $t('components.compose_email.story_attachment.body') }}
    <br><br>
    <template v-if="shouldHavePDFAttached">
      <a :href="linkToPdf">{{ $t('components.email_pr_attachment.link_to_attached_pdf') }}</a>
      <br><br>
    </template>
    <template v-if="mode === 'both'">
      {{ $t('components.compose_email.story_attachment.attached_as_plain') }}:<br><br>
    </template>
    <template v-if="shouldHaveInlineStory">
      <inline-story :story="entity" />
    </template>
    <template v-if="addAttachments">
      <template v-if="imageAttachments.length">
        <br>
        <h4>{{ $t('components.compose_email.story_attachment.image_attachments') }}</h4>
        <br>
        <template v-for="attachment in imageAttachments">
          <a
            :key="attachment.id"
            :href="attachment.path"
            style="margin-right: 10px"
          >
            <img :src="attachment.path" :alt="attachment.name" width="100">
          </a>
        </template>
        <br>
      </template>
      <template v-if="fileAttachments.length">
        <br>
        <h4>{{ $t('components.compose_email.story_attachment.file_attachments') }}</h4>
        <br>
        <div
          v-for="attachment in fileAttachments"
          :key="attachment.id"
        >
          <a :href="attachment.path">
            {{ attachment.name }}
          </a>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import EmailAttachmentMixin from '@/components/emails/compose/EmailAttachmentMixin'
import InlineStory from '@/components/emails/compose/templates/InlineStory'
import { Story } from '@hypefactors/shared/js/models/Story'
import { buildUrlWithParams } from '@hypefactors/shared/js/utils/UrlUtils'

export default {
  components: { InlineStory },
  mixins: [EmailAttachmentMixin],
  data () {
    return {
      /**
       * @type HF_Story
       */
      entity: new Story()
    }
  },

  computed: {
    url () {
      return buildUrlWithParams(`stories/${this.entityId}`, {
        'include': [
          'newsrooms',
          'pr_styles',
          this.addAttachments ? 'files' : null
        ]
      })
    },

    error () {
      return this.$t('errors.cannot_fetch_email_story_attachment')
    },

    linkToPdf () {
      return this.$route.query.link_to_pdf
    },

    addAttachments () {
      return JSON.parse(this.$route.query.add_attachments || false)
    },

    shouldHaveInlineStory () {
      return ['inline', 'both'].includes(this.mode)
    },

    shouldHavePDFAttached () {
      return ['pdf', 'both'].includes(this.mode)
    },

    imageAttachments () {
      return this.entity.files.filter((file) => file.type === 'image')
    },

    fileAttachments () {
      return this.entity.files.filter((file) => file.type !== 'image')
    }
  },

  methods: {
    prepareAttachmentData () {
      return {
        subject: this.entity.headline,
        body: '<br>' + this.$el.innerHTML
      }
    },
    __transformResponse (story) {
      return new Story(story)
    }
  }
}
</script>
