<template>
  <page-section
    class="ComposeEmail"
    :page-title="$t('pages.emails.compose.sidebar_title')"
    :page-tooltip="$t('pages.emails.compose.sidebar_description')"
  >
    <router-link
      v-if="goBackPath"
      :to="goBackPath"
      class="has-text-dark m-b-m is-inline-block is-narrow has-text-weight-semibold"
    >
      <div class="icon">
        <v-icon type="angle-left" />
      </div>
      <span>{{ goBackText }}</span>
    </router-link>

    <form-field
      v-if="!emailId"
      :label="$t('forms.send_to')"
      :validator="$v.form.recipients"
    >
      <email-recipient-selector
        v-model="form.recipients"
        :attached-recipient="attachedRecipient"
        @groups="relationGroups = $event"
      />
    </form-field>

    <form-field :label="$t('forms.subject')" :validator="$v.form.subject">
      <input
        v-model="form.subject"
        class="input"
        name="subject"
        type="text"
      >
    </form-field>

    <component
      :is="`${type}-attachment`"
      v-if="!emailId && hasEntity"
      :loading.sync="isLoading"
      @attach="attachEntity"
    />

    <div v-loading="isLoading" class="ComposeEmail__body">
      <form-field
        :validator="$v.form.body"
        :label="$t('pages.emails.compose.email_body')"
        data-testid="ComposeEmail__FormField"
      >
        <froala-wrapper
          v-model="form.body"
          :config="bodyEditorConfig"
          class="content border-default"
          data-testid="ComposeEmail__content"
          name="signature"
          rows="10"
          tabindex="0"
          @hook:created="registerFroalaButtons"
        />
      </form-field>
    </div>

    <v-separator class="m-v-l" />

    <heading :serif="false" size="4" weight="bold">
      {{ $t('pages.emails.compose.scheduling') }}
    </heading>

    <form-field>
      <el-switch v-model="form.scheduling_config.status" />

      <div class="columns mt-4">
        <div class="column is-3">
          <form-field
            key="schedule_date"
            label="Schedule date"
            name="schedule_date"
          >
            <el-date-picker
              v-model="form.scheduling_config.date"
              :disabled="!form.scheduling_config.status"
              :picker-options="scheduleDatePickerOptions"
              type="date"
              format="dd MMMM yyyy"
              value-format="timestamp"
            />
          </form-field>
        </div>

        <div class="column is-3">
          <form-field
            key="schedule_time"
            label="Schedule time"
            name="schedule_time"
          >
            <el-select
              v-model="form.scheduling_config.time"
              :disabled="!form.scheduling_config.status"
              filterable
            >
              <el-option
                v-for="time in times"
                :key="time.value"
                :label="time.label"
                :value="time.value"
                :disabled="time.disabled"
              />
            </el-select>
          </form-field>
        </div>

        <div class="column is-3">
          <form-field
            key="schedule_timezone"
            :label="$t('general.timezone')"
            name="schedule_timezone"
          >
            <timezone-picker
              v-model="form.scheduling_config.timezone"
              :disabled="!form.scheduling_config.status"
            />
          </form-field>
        </div>
      </div>
    </form-field>

    <div class="is-flex is-aligned-justify m-t-l">
      <v-button
        class="is-dark is-plain"
        @click.prevent="goBack"
      >
        {{ $t('forms.cancel') }}
      </v-button>

      <v-button
        :loading="form.isLoading"
        class="is-primary"
        data-testid="ComposeEmail__sendEmail"
        @click.prevent="submit"
      >
        <template v-if="emailId">
          {{ $t('forms.save') }}
        </template>
        <template v-else-if="!form.scheduling_config.status">
          {{ $t('forms.send') }}
        </template>
        <template v-else>
          {{ $t('forms.schedule') }}
        </template>
      </v-button>
    </div>
  </page-section>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'

import Form from '@/services/forms/Form'

import { removeDuplicatesSimple } from '@hypefactors/shared/js/utils/arrayUtils'

import TimezonePicker from '@/components/core/TimezonePicker'
import StoryAttachment from '@/components/emails/compose/StoryAttachment'
import ClippingAttachment from '@/components/emails/compose/ClippingAttachment'
import SocialShareAttachment from '@/components/emails/compose/SocialShareAttachment'
import EmailRecipientSelector from '@/components/emails/compose/EmailRecipientSelector'
import { HF_CUSTOM_STYLE_FONT_FAMILIES } from '@hypefactors/shared/js/constants/fonts'

function createForm () {
  return new Form({
    type: { value: 'story' },
    recipients: {
      value: [],
      rules: {
        required: requiredIf(function () {
          return !this.emailId
        })
      }
    },
    subject: { value: '', rules: { required } },
    body: { value: '' },
    scheduling_config: {
      value: {
        status: false,
        time: null,
        date: null,
        timezone: null
      }
    }
  })
}

export default {
  components: {
    EmailRecipientSelector,
    ClippingAttachment,
    StoryAttachment,
    SocialShareAttachment,
    TimezonePicker
  },

  data () {
    return {
      isLoading: true,
      emailId: null,
      form: createForm(),
      relationGroups: [],
      bodyEditorConfig: {
        useClasses: false,
        placeholderText: '',
        pluginsEnabled: [
          'colors',
          'align',
          'fontFamily',
          'fontSize',
          'quickInsert',
          'paragraphFormat',
          'link',
          'lists',
          'table',
          'image',
          'draggable'
        ],
        toolbarButtons: [
          'bold',
          'italic',
          'color',
          'align',
          'fontFamily',
          'fontSize',
          'paragraphFormat',
          'insertLink',
          'formatOL',
          'formatUL',
          'recipient_name_placeholder',
          'recipient_company_placeholder',
          'insertTable',
          'insertImage'
        ],
        paragraphFormat: {
          N: 'Normal',
          H3: 'Heading'
        },
        fontFamily: HF_CUSTOM_STYLE_FONT_FAMILIES().reduce((all, current) => {
          all[current.rawValue] = current.label
          return all
        }, {}),
        paragraphFormatSelection: true,
        linkAlwaysBlank: true,
        linkAlwaysNoFollow: false,
        linkEditButtons: ['linkOpen', 'linkEdit'],
        linkInsertButtons: [],
        linkList: [],
        heightMin: 300
      },
      scheduleDatePickerOptions: {
        disabledDate (time) {
          const today = new Date()

          // TODO: This is a hack, so we can select the today date...
          const yesterday = new Date(today)
          yesterday.setDate(yesterday.getDate() - 1)

          return time.getTime() + 1 <= yesterday
        }
      }
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  computed: {
    ...mapGetters(['currentUser', 'userEmailSettings']),

    /**
     * Returns the extra type in the url
     * @return {string}
     */
    type () {
      return this.$route.query.type
    },

    entityId () {
      return this.$route.query.entity_id
    },

    hasEntity () {
      return !!this.type
    },

    attachedRecipient () {
      const recipient = this.$route.query.recipient && JSON.parse(this.$route.query.recipient)
      return recipient || null
    },

    goBackPath () {
      switch (this.type) {
        case 'story':
          return { name: 'story.edit', params: { storyId: this.entityId } }
        case 'clipping':
          return { name: 'clippings.list' }
        default:
          return ''
      }
    },

    goBackText () {
      switch (this.type) {
        case 'story':
          return this.$t('pages.emails.compose.back_to_story')
        case 'clipping':
          return this.$t('pages.emails.compose.back_to_clipping')
        default:
          return ''
      }
    },

    times () {
      let times = []

      let date = this.form.scheduling_config.date

      if (date) {
        date = moment.unix(date / 1000)
      }

      Array.from({
        length: 48
      }, (_, hour) => {
        const time = date || moment()
        time.hour(Math.floor(hour / 2))
        time.minutes((hour % 2 === 0 ? 0 : 30))

        times.push({
          value: time.format('HH:mm:ss'),
          label: time.format('HH:mm'),
          disabled: !date || time.isBefore()
        })
      })

      return times
    }
  },

  mounted () {
    this.emailId = this.$route.params.emailId

    if (this.emailId) {
      this.fetchEmail()
    } else {
      this.form.scheduling_config.timezone = this.currentUser.timezone

      if (!this.hasEntity) {
        this.appendSignature()
        this.isLoading = false
      }

      if (this.attachedRecipient) {
        this.form.recipients.push(this.attachedRecipient.relation_id)
      }
    }
  },

  beforeRouteUpdate (to, from, next) {
    this.form.reset()
    this.$v.form.$reset()
    next()
  },

  methods: {
    async fetchEmail () {
      await this.$api.getData(`/emails/${this.emailId}`)
        .then(email => {
          if (!email.is_scheduled) {
            this.$notify.error({
              title: 'Error',
              duration: 5000,
              message: this.$t('errors.email_could_not_be_found')
            })

            return this.$router.push({ name: 'connect.facts' })
          }

          this.form.merge(email)

          this.isLoading = false
        })
        .catch(() => {
          this.$notify.error({
            title: 'Error',
            duration: 5000,
            message: this.$t('errors.email_could_not_be_found')
          })

          this.$router.push({ name: 'connect.facts' })
        })
    },
    attachEntity (data) {
      this.isLoading = false

      data.subject && (this.form.subject = data.subject)
      this.form.body += data.body
      !data.skipSignature && this.appendSignature()
      this.$notify.success(this.$t('success.email_subject_prefilled_with_attachment_title'))
    },

    appendSignature () {
      const signature = this.userEmailSettings.signature
      if (signature) {
        this.form.body += `--<br/> ${signature}`
      }
    },

    registerFroalaButtons () {
      /* eslint-disable no-undef */
      $.FroalaEditor.DefineIcon('recipient_name_placeholder', { NAME: 'user' })
      $.FroalaEditor.RegisterCommand('recipient_name_placeholder', {
        title: this.$t('pages.emails.compose.recipient_name_placeholder_title'),
        focus: false,
        undo: true,
        refreshAfterCallback: true,
        callback () {
          this.html.insert('[[RECIPIENT_NAME]]')
        }
      })

      // $.FroalaEditor.DefineIcon('recipient_company_placeholder', { NAME: 'building' })
      // $.FroalaEditor.RegisterCommand('recipient_company_placeholder', {
      //   title: this.$t('pages.emails.compose.recipient_company_placeholder_title'),
      //   focus: false,
      //   undo: true,
      //   refreshAfterCallback: true,
      //   callback () {
      //     this.html.insert('[[RECIPIENT_COMPANY]]')
      //   }
      // })
    },

    async goBack () {
      try {
        await this.$confirm(this.$t('warnings.confirm_email_create_cancel'))
        this.$router.back()
      } catch (err) { }
    },

    getGroupRelationsFromId (id) {
      const group = this.relationGroups.find(group => group.id === id)
      if (!group) return [id]

      return group.relations_ids
    },

    submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      if (!this.emailId) {
        this.createEmail()
      } else {
        this.updateEmail()
      }
    },

    async createEmail () {
      this.form.setPayloadTransformer((payload) => {
        const recipients = payload.recipients.reduce((all, relation) => {
          return all.concat(...this.getGroupRelationsFromId(relation))
        }, [])

        return {
          ...payload,
          recipients: removeDuplicatesSimple(recipients)
        }
      })

      this.form.submit('post', `/brands/${this.activeBrandId}/emails`)
        .then((response) => {
          this.$notify.success({
            title: 'Success',
            duration: 5000,
            message: this.$t('success.email_created_successfully')
          })

          this.$router.push({ name: 'connect.facts' })
        })
        .catch(error => {
          this.$displayRequestError(error)
        })
    },

    async updateEmail () {
      this.form.submit('post', `/brands/${this.activeBrandId}/emails/${this.emailId}`)
        .then((response) => {
          this.$notify.success({
            title: 'Success',
            duration: 5000,
            message: this.$t('success.email_updated_successfully')
          })

          this.$router.push({ name: 'connect.facts' })
        })
        .catch(error => {
          this.$displayRequestError(error)
        })
    }
  }
}
</script>

<style lang="scss">
.content .fr-view {
  ul, ol {
    margin-left: 0;
    padding-left: 2em;
    margin-top: 1em;
  }

  ol {
    list-style: decimal outside;
  }

  ul {
    list-style: disc outside;
  }
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>
