export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      entity: null
    }
  },

  computed: {
    entityId () {
      return this.$route.query.entity_id
    },
    mode () {
      return this.$route.query.mode
    },
    url () {
      throw Error('Set URL')
    }
  },

  mounted () {
    if (!this.prepareAttachmentData) {
      throw new Error('Implement prepareAttachmentData method')
    }
    this.fetchData()
  },

  methods: {
    /**
     * A generic fetchData method for all components,
     * implementing this mixin.
     * @return {Promise}
     */
    fetchData () {
      const payload = {}
      this.$emit('update:loading', true)
      return this.$api.get(this.url, payload)
        .then(response => {
          this.entity = this.__transformResponse(response.data.data)
          this.__emitAttachmentTemplate()
        })
        .catch(error => {
          this.$displayRequestError(error, this.error)
        })
        .finally(() => {
          this.$emit('update:loading', false)
        })
    },

    /**
     * Optional response transformer
     * Can be overwritten on the component implementing the mixin
     * @param {object} response
     * @return {*}
     * @private
     */
    __transformResponse (response) {
      return response
    },

    /**
     * Emits the prepared data up to the parent
     * @return {Promise<void>}
     * @private
     */
    async __emitAttachmentTemplate () {
      await this.$nextTick()
      this.$emit('attach', this.prepareAttachmentData())
    }
  }
}
