/**
 * @typedef {Object} HF_StoryPRStylesObject
 * @property {string} fontSize
 * @property {string} fontFamily
 */

/**
 * @typedef {Object} HF_StoryPRStyles
 * @property {HF_StoryPRStylesObject} headline
 * @property {HF_StoryPRStylesObject} subheadline
 * @property {HF_StoryPRStylesObject} copytext
 * @property {HF_StoryPRStylesObject} boilerplate
 * @property {HF_StoryPRStylesObject} contact
 */

/**
 * Generates Custom story Styles object
 * @return HF_StoryPRStyles
 */
export const PR_STYLES_FACTORY = () => ({
  headline: {
    fontSize: '',
    fontFamily: ''
  },
  subheadline: {
    fontSize: '',
    fontFamily: ''
  },
  copytext: {
    fontSize: '',
    fontFamily: ''
  },
  boilerplate: {
    fontSize: '',
    fontFamily: ''
  },
  contact: {
    fontSize: '',
    fontFamily: ''
  }
})

/**
 * Returns the default empty custom styles object
 * @return HF_StoryPRStyles
 * @constructor
 */
export const EMPTY_STYLES_FACTORY = () => ({
  headline: {},
  subheadline: {},
  copytext: {},
  boilerplate: {},
  contact: {}
})
